import { render, staticRenderFns } from "./baojingshijian.vue?vue&type=template&id=2d0fa290&scoped=true"
import script from "./baojingshijian.vue?vue&type=script&lang=js"
export * from "./baojingshijian.vue?vue&type=script&lang=js"
import style0 from "./baojingshijian.vue?vue&type=style&index=0&id=2d0fa290&prod&lang=css"
import style1 from "./baojingshijian.vue?vue&type=style&index=1&id=2d0fa290&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0fa290",
  null
  
)

export default component.exports