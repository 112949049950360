<template>
	<div>
		<div>

			<div class="leixingdexuanze">

				<span style="color: #fff;font-size: 17px;line-height: 32px;float: left;margin-left: 24px;">等级：</span>
				<el-select v-model="dengjixuanze" multiple collapse-tags style="margin-left: 0px;float: left;"
					placeholder="请选择等级">
					<el-option v-for="item in dengjilist" :key="item.value" :label="item.label"
						:value="parseInt(item.value)">
					</el-option>
				</el-select>
				<div class="chaxunanniu" @click="getbaojinglist()"><span
						style="margin-left: 20px;cursor: pointer;">查询</span>
				</div>
			</div>
			<div class="biaoti">
				<span class="bt_span" style="width:80px;">序号</span>
				<span class="bt_span" style="width:130px;">类型</span>
				<span class="bt_span" style="width:170px;">时间</span>
				<span class="bt_span" style="width:130px;">等级</span>
				<span class="bt_span" style="width:220px;">内容</span>
				<span class="bt_span" style="width:110px;">状态</span>
				<span class="bt_span" style="width:100px;">操作</span>
			</div>
			<div class="xia" v-loading="loading">
				<template v-if="this.total != 0">
					<div v-for="(item, index) of liebiaoneirong" :key="index">
						<div class="neirong" :class="index % 2 == 0 ? 'beijingwuse' : 'beijinglanse'">
							<span class="nr_span" style="width:80px;">{{ index + 1 + (currentPage - 1) * 20
									}}</span>
							<span class="nr_span" style="width:130px;" v-if="item.eventType != 0">{{
										event_type[item.eventType] }}</span>
							<span class="nr_span" style="width:130px;" v-if="item.eventType == 0">{{ item.alertName
									}}</span>
							<span class="nr_span" style="width:170px;">{{ item.createTime }}</span>
							<span class="nr_span" style="width:130px;color: #ff0000;"
								v-if="item.eventLevel == 1">1级</span>
							<span class="nr_span" style="width:130px;color: #ff7600;"
								v-if="item.eventLevel == 2">2级</span>
							<span class="nr_span" style="width:130px;color: #ffff00;"
								v-if="item.eventLevel == 3">3级</span>
							<el-tooltip :content="item.message" placement="top" effect="light">
								<span class="nr_span" style="width:220px;">{{ item.message }}</span>
							</el-tooltip>

							<span class="nr_span" style="width:110px;color: #91ecff;" v-if="item.status == 1"
								@click="getyidu(item.alertId, index)">未读</span>

							<span class="nr_span" style="width:110px;" v-if="item.status != 1">已读</span>
							<span class="nr_span" style="width:100px;color: #91ecff;"
								@click="handleClickcktp(item.alertId, item.message, item.fileUrl, item.alertType, index, item)">查看</span>
						</div>
					</div>
				</template>
				<template v-if="this.total == 0">
					<span style="
								color: #218fce;
								width: 100%;
								height: 100%;
								
								display: flex;
								justify-content: center;
								align-items: center;
								flex-direction: column;
								font-size: 17px;
								text-align: center
								">暂无数据</span>
				</template>
			</div>
			<div class="fanye" v-if="this.total <= 20">
			</div>
			<div class="fanye" v-if="this.total > 20">
				<div class="block">
					<el-pagination background layout="prev, pager, next" :total="total" :page-size="20"
						:current-page.sync="currentPage" @current-change="getbaojinglist">
					</el-pagination>
				</div>
			</div>
		</div>
		<chakantupian ref="dialog" v-if="cktp" :cktpId="cktpId" :cktpwen="cktpwen" :cktpurl="cktpurl" :leixing="leixing"
			:zongitem="zongitem" @on-close="cktp = false">
		</chakantupian>



	</div>
</template>

<script>
	import {
		baojinglist
	} from "@/api/dapingshuiqingcanbao/baojinglist";
	import {
		getDictData
	} from "@/utils/dict";
	import {
		yidu
	} from "@/api/dapingshuiqingcanbao/yidu";
	import chakantupian from "@/components/x-station/components/chakantupian";

	export default {
		name: "x-staion",
		props: {
			deviceId: {
				type: Number,
				default: undefined
			},
		},

		components: {
			chakantupian,

		},

		data: () => {
			return {
				dengjilist: [{
					value: '1',
					label: '1级'
				}, {
					value: '2',
					label: '2级'
				}, {
					value: '3',
					label: '3级'
				}],
				dengjixuanze: null,

				cktp: false,
				cktpId: null,
				cktpurl: null,
				cktpwen: null,
				leixing: null,

				total: -1,
				liebiaoneirong: [],
				// 类型字典
				event_type: [],
				currentPage: 1,


				loadingType: true,
				selectTitle: "", //选中的标题
				stationInfo: {},
				menuData: [],
				resData: {}, //设备数据
				baojinglistdeviceId: null,
				chuandengji: "",
			}
		},
		created() {},
		mounted() {
			this.baojinglistdeviceId = Number(this.deviceId)
			getDictData("event_type").then((val) => {
				this.event_type = val;
			});
			this.getbaojinglist()
		},
		beforeDestroy() {},
		methods: {

			emitLoading(type) {
				this.loading = type;
				this.$emit('on-loading', type)
				console.log(this.loading)
			},

			getyidu(ID, index) {
				this.dijige = index
				yidu({
					alertId: ID,
					status: 2
				}).then(response => {
					console.log("已读成功")
					this.liebiaoneirong[this.dijige].status = 2
					console.log(this.liebiaoneirong[this.dijige].status)

				})
			},
			handleClickcktp(data, wen, url, lei, index, item) {
				console.log("当前被点击的id" + data)
				this.cktpId = data;
				this.cktpId = Number(this.cktpId)
				this.cktpwen = wen;
				this.cktpurl = url;
				this.leixing = lei;
				this.leixing = Number(this.leixing);
				this.zongitem = item;
				this.cktp = true;
				this.getyidu(this.cktpId, index)
			},

			getbaojinglist() {
				this.chuandengji = this.dengjixuanze.join(',')
				this.emitLoading(true);
				baojinglist({
					pageNum: this.currentPage,
					pageSize: 20,
					deviceId: this.baojinglistdeviceId, //设备
					// alertLevel: this.yujing, //告警级别（1=动态消息，2=报警事件，3=预警事件）
					// fenleieventType: this.chuanleixing,
					fenleilevel: this.chuandengji,
					// associatedReservoirId:this.chuanedshuikuId,
					// associatedIrrigatedAreaId: this.guanquIdtwo
				}).then(response => {
					this.liebiaoneirong = response.rows;
					this.total = response.total
					this.emitLoading(false);
					console.log("成功")
				})
			},
		}
	}
</script>

<style>
	.leixingdexuanze .el-tag.el-tag--info {
		background-color: #84bfe19c;
		/* border-color: #e9e9eb; */
		color: #ffffff;
	}
	.el-select__tags{
		max-width: 100px !important;
	}

	.leixingdexuanze .el-tag {
		border-width: 0px;
	}

	.leixingdexuanze ::placeholder {
		color: white !important;
	}

	.leixingdexuanze .el-select .el-input .el-select__caret {
		color: #ffffff;
		font-size: 16px;
		margin-top: 12px;
	}

	.leixingdexuanze .el-select>.el-input {
		display: block;
		width: 175px;
	}

	.leixingdexuanze .el-input__icon {
		height: 30%;
		line-height: 6px;
	}

	.leixingdexuanze .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.leixingdexuanze .el-input--prefix .el-input__inner {
		padding-left: 10px !important;
		height: 30px;
		margin-top: 4px;
		color: #fff;
	}

	.leixingdexuanze .el-input--suffix .el-input__inner {
		padding-right: 10px !important;
		height: 30px;
		color: #fff;
	}

	.leixingdexuanze .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.leixingdexuanze .el-input__inner {
		height: 37px;
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}
</style>
<style scoped>
	.chaxunanniu {
		color: #fff;
		margin-left: 20px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 0px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #1a6e9f;

	}

	.chaxunanniu:hover {
		color: #fff;
		margin-left: 20px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 0px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #218fce;

	}

	.leixingdexuanze {
		width: 990px;
		height: 50px;
		margin-top: 15px;
		margin-left: 47.5px;
		float: left;
		/* border: #fff solid 1px; */
	}

	.fanye {
		width: 940px;
		height: 58px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;

	}

	.block {
		height: 46px;
		margin-top: 12px;
		float: right;

	}

	.biaoti {

		background-color: #104d7b;
		width: 940px;
		height: 45px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */

	}

	.bt_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 45px;
		cursor: pointer;
		display: block;
		font-size: 18px;
		line-height: 48px;
		font-weight: bold;
		text-align: center;

	}

	.nr_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 54px;
		cursor: pointer;
		/* display: block; */
		font-size: 16px;
		line-height: 55px;
		text-align: center;

		/* 设置为单行文本 */
		white-space: nowrap;
		/* 设置溢出时显示省略号 */
		text-overflow: ellipsis;
		/* 设置元素宽度，并让文本在该宽度内截断 */
		/* 设置元素内容溢出部分隐藏 */
		overflow: hidden;
		/* 设置元素为块级元素，这样才能进行宽度限制 */
		display: inline-block;

	}


	.neirong {

		/* background-color: #104d7b; */
		width: 940px;
		height: 54px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		border-bottom: #1c7bb1 solid 1px;


	}

	.xia {
		width: 940px;
		height: 468px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-page-twobg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}

	.x-tc-cnt {
		height: 680px;
		width: 1050px;
		overflow: auto;
	}

	.x-card {
		width: 230px;
		height: 270px;
		margin-right: 33px;
		float: left;
	}

	.x-card:nth-child(4n) {
		margin-right: 0px;
	}

	.x-card-cnt {
		width: 250px;
		height: 230px;
	}

	.x-sm-title-bg {
		background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
		background-size: 100% 100%;
		width: 220px;
		height: 35px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		font-family: douyuFont-2;
		font-size: 14px;
	}

	.x-aq-cnt {
		width: 50%;
		margin-left: 27%;
		margin-top: 0px;
	}
</style>