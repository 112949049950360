export function panduanbackClick() {
	console.log("点击返回")
	console.log("当前存储的上次页面路由：", localStorage.getItem("shangciluyou"))
	//上次为水库
	if (localStorage.getItem("shangciluyou") == '/zongHeYuTu/reservoirMapOnly') {
		this.$router.push('/ShuNao');
	}
	//上次为水闸
	else if (localStorage.getItem("shangciluyou") == '/zongHeYuTu/sluiceMapOnly') {
		this.$router.push('/ShuNao');
	}
	//上次为河流
	else if (localStorage.getItem("shangciluyou") == '/zongHeYuTu/riversMapOnly') {
		this.$router.push('/ShuNao');
	}
	//上次为泵站
	else if (localStorage.getItem("shangciluyou") == '/zongHeYuTu/pumpMapOnly') {
		this.$router.push('/ShuNao');
	}
	//上次为灌区边界
	else if (localStorage.getItem("shangciluyou") == '/zongHeYuTu/irrigatedAreaMapOnly') {
		this.$router.push('/ShuNao');
	}
	//上次为灌区
	else if (localStorage.getItem("shangciluyou") == '/guanquNongCunShuiLiYuTu' || localStorage.getItem(
		"shangciluyou") == '/guanquNongCunShuiLiYuTu/') {
		console.log(localStorage.getItem("bindingId"))
		this.$router.push('/guanquNongCunShuiLiYuTu/?bindingId=' + localStorage.getItem("bindingId"));
		window.closezuoyou(0);
	}
	//上次为水库
	else if (localStorage.getItem("shangciluyou") == '/YongZhenShuiKuYuTunew' || localStorage.getItem("shangciluyou") ==
		'/YongZhenShuiKuYuTunew/') {
		this.$router.push('/YongZhenShuiKuYuTunew');
		window.closezuoyou(0);
	}
	//上次为水价改革
	else if (localStorage.getItem("shangciluyou") == '/SJGGZongHeYuTu' || localStorage.getItem("SJGGZongHeYuTu") ==
		'/YongZhenShuiKuYuTunew/') {
		this.$router.push('/SJGGZongHeYuTu');
		window.closezuoyou(0);
	} else {
		this.$router.push(localStorage.getItem("shangciluyou"));
	}
}