import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: () => import('../views/login')
	}, {
		path: '/gisTest',
		name: 'gisTest',
		component: () => import('../views/gisTest')
	}, {
		path: '/login',
		name: 'login',
		component: () => import('../views/login')
	},


	{
		path: '/wulianganzhi',
		name: 'IOT平台',
		component: () => import('../views/wulianganzhi'),
		children: [{
			path: '',
			name: 'IOT平台',
			component: () => import('../views/wulianganzhi/zhuyeindex'),
		}
		],
	},{
		path: '/wulianganzhiYuTu',
		name: 'IOT平台舆图',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
				path: '',
				name: 'IOT平台主舆图',
				component: () => import('../views/ZongHeYuTu/main')
			},
		]
	},

	{
		path: '/shujudiban',
		name: '汇集交互平台',
		component: () => import('../views/shujudiban'),
		children: [{
			path: '',
			name: '汇集交互平台',
			component: () => import('../views/shujudiban/zhuyeindex'),
		}
		],
	},

	{
		path: '/laifangdengji',
		name: '二维码访问',
		component: () => import('../views/luzhuangguanqu/oabangong/laifangdengji')
	},
	{
		path: '/ShuNao',
		name: '智水枢脑',
		component: () => import('../views/ShuNao')
	}, {
		path: '/videoOne',
		name: '查看监控',
		component: () => import('../views/videoOne')
	}, {
		path: '/HouTaiLuRu',
		name: '后台录入',
		component: () => import('../views/HouTaiLuRu')
	}, {
		path: '/CaiDanLuRu',
		name: '菜单录入',
		component: () => import('../views/CaiDanLuRu')
	}, {
		path: '/zongHeYuTu',
		name: '综合舆图',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
			path: '',
			name: '综合舆图',
			component: () => import('../views/ZongHeYuTu/main')
		}, {
			path: 'riversMap',
			name: '河道舆图',
			component: () => import('../views/ZongHeYuTu/riversMap')
		}, {
			path: 'riversMapOnly',
			name: '河道舆图详情',
			component: () => import('../views/ZongHeYuTu/riversMapOnly')
		},{
			path: 'ganquMap',
			name: '干渠舆图详情',
			component: () => import('../views/ZongHeYuTu/ganquMap')
		}, {
			path: 'reservoirMap',
			name: '水库舆图',
			component: () => import('../views/ZongHeYuTu/reservoirMap')
		}, {
			path: 'reservoirMapOnly',
			name: '水库舆图详情',
			component: () => import('../views/ZongHeYuTu/reservoirMapOnly')
		}, {
			path: 'shuibaoMapOnly',
			name: '水保监管详情',
			component: () => import('../views/ZongHeYuTu/shuibaoMapOnly')
		}, {
			path: 'irrigatedAreaMap',
			name: '灌区舆图',
			component: () => import('../views/ZongHeYuTu/irrigatedAreaMap')
		}, {
			path: 'irrigatedAreaMapOnly',
			name: '灌区舆图详情',
			component: () => import('../views/ZongHeYuTu/irrigatedAreaMapOnly')
		}, {
			path: 'sluiceMap',
			name: '水闸舆图',
			component: () => import('../views/ZongHeYuTu/sluiceMap')
		}, {
			path: 'sluiceMapOnly',
			name: '水闸舆图详情',
			component: () => import('../views/ZongHeYuTu/sluiceMapOnly')
		}, {
			path: 'pumpMap',
			name: '泵房舆图',
			component: () => import('../views/ZongHeYuTu/pumpMap')
		}, {
			path: 'pumpMapOnly',
			name: '泵房舆图详情',
			component: () => import('../views/ZongHeYuTu/pumpMapOnly')
		}, {
			path: 'jianshexiangmuMapOnly',
			name: '建设项目详情',
			component: () => import('../views/ZongHeYuTu/jianshexiangmuMapOnly')
		}, {
			path: 'shifangongchengMapOnly',
			name: '示范工程',
			component: () => import('../views/ZongHeYuTu/shifangongchengMapOnly')
		}],
	}, {
		path: '/heHuGuanLi',
		name: '河湖管理',
		component: () => import('../views/heHuGuanLi/index.vue')
	}, {
		path: '/nongCunShuiLi',
		name: '农村水利',
		component: () => import('../views/nongCunShuiLi'),
	},{
		path: '/nongCunShuiLi2',
		name: '农村水利',
		component: () => import('../views/nongCunShuiLi'),
	}, 
	{
		path: '/yunxingguanli',
		name: '枢脑运行管理',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
				path: '',
				name: '枢脑运行管理',
				component: () => import('../views/ZongHeYuTu/main')
			},
		]
	},

	{
		path: '/ShuiJiaGaiGe',
		name: '水价改革',
		component: () => import('../views/ShuiJiaGaiGe'),
		children: [{
			path: '',
			name: '水价改革',
			component: () => import('../views/ShuiJiaGaiGe/shuijiagaigeindex'),
		}, {
			path: 'JiangLiBuTie',
			name: '奖励补贴',
			component: () => import('../views/ShuiJiaGaiGe/JiangLiBuTie'),
		}, {
			path: 'ShuiJiaXingCheng',
			name: '水价形成',
			component: () => import('../views/ShuiJiaGaiGe/ShuiJiaXingCheng'),
		}, {
			path: 'GongChengYunGuan',
			name: '工程运管',
			component: () => import('../views/ShuiJiaGaiGe/GongChengYunGuan'),
		}, {
			path: 'YongShuiGuanLi',
			name: '用水管理',
			component: () => import('../views/ShuiJiaGaiGe/YongShuiGuanLi'),
		},
		// {
		// 	path: 'SJGGZongHeYuTu',
		// 	name: '水价综合舆图',
		// 	component: () => import('../views/ShuiJiaGaiGe/SJGGZongHeYuTu'),
		// },
		// {
		// 	path: 'SJGGZongHeYuTu',
		// 	name: '水价改革舆图',
		// 	component: () => import('../views/ZongHeYuTu/main')
		// },
		],
	},{
		path: '/SJGGZongHeYuTu',
		name: '水价改革',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
				path: '',
				name: '水价改革主舆图',
				component: () => import('../views/ZongHeYuTu/main')
			},
		]
	},{
		path: '/shapantuiyan',
		name: '沙盘推演',
		component: () => import('../views/luzhuangguanqu/shapantuiyan'),
		children: [{
				path: '',
				name: '沙盘推演',
				component: () => import('../views/luzhuangguanqu/shapantuiyan/zhumulu')
			},
		]
	},

	{
		path: '/nongCunShuiLi/GuanQuYuTu',
		name: '灌区舆图',
		component: () => import('../views/nongCunShuiLi/GuanQuYuTu'),
		children: [
			// {
			//     path: '',
			//     name: '灌区枢脑',
			//     component: () => import('../views/nongCunShuiLi/GuanQuYuTu/main')
			// },
			{
				path: 'GuanQuShuNao',
				name: '管理驾驶舱',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/GuanQuShuNao')
			},
			{
				path: 'GuanQuShuNao2',
				name: '管理驾驶舱',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/GuanQuShuNao')
			},
			{
				path: 'pumpMapOnly',
				name: '泵房舆图详情',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/pumpMapOnly')
			},
			{
				path: 'ShuiFeiGuanLi',
				name: '水费管理',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/ShuiFeiGuanLi')
			}, {
				path: 'ShangQingQiXiang',
				name: '墒情气象',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/ShangQingQiXiang')
			}, {
				path: 'ZhaMenYuanCheng',
				name: '闸门远程',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/ZhaMenYuanCheng')
			}, {
				path: 'BengZhanYuanCheng',
				name: '泵站远程',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/BengZhanYuanCheng')
			}, {
				path: 'YuYanYuAn',
				name: '沙盘预演',
				component: () => import('../views/nongCunShuiLi/GuanQuYuTu/YuYanYuAn')
			}
		],
	},

	{
		path: '/luzhuangguanqu/GuanQuYuTu',
		name: '灌区舆图',
		component: () => import('../views/luzhuangguanqu/GuanQuYuTu'),
		children: [
			// {
			//     path: '',
			//     name: '灌区枢脑',
			//     component: () => import('../views/luzhuangguanqu/GuanQuYuTu/main')
			// },
			{
				path: 'GuanQuShuNao',
				name: '灌区管理驾驶舱',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/GuanQuShuNao')
			},
			{
				path: 'GuanQuShuNao2',
				name: '灌区管理驾驶舱',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/GuanQuShuNao')
			},
			{
				path: 'guangaijuece',
				name: '灌溉决策',
				component: () => import('../views/luzhuangguanqu/guangaijuece')
			},
			{
				path: 'peizhidiaodu',
				name: '配置调度',
				component: () => import('../views/luzhuangguanqu/peizhidiaodu')
			},
			{
				path: 'zaihaifangyu',
				name: '灾害防御',
				component: () => import('../views/luzhuangguanqu/zaihaifangyu')
			},
			{
				path: 'gongyongguanli',
				name: '供用管理',
				component: () => import('../views/luzhuangguanqu/gongyongguanli')
			},
			{
				path: 'biaozhunguanli',
				name: '标准管理',
				component: () => import('../views/luzhuangguanqu/biaozhunguanli')
			},
			{
				path: 'oabangong',
				name: 'OA办公',
				component: () => import('../views/luzhuangguanqu/oabangong')
			},

			{
				path: 'pumpMapOnly',
				name: '泵房舆图详情',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/pumpMapOnly')
			},
			{
				path: 'ShuiFeiGuanLi',
				name: '水费管理',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/ShuiFeiGuanLi')
			}, {
				path: 'ShangQingQiXiang',
				name: '墒情气象',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/ShangQingQiXiang')
			}, {
				path: 'ZhaMenYuanCheng',
				name: '闸门远程',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/ZhaMenYuanCheng')
			}, {
				path: 'BengZhanYuanCheng',
				name: '泵站远程',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/BengZhanYuanCheng')
			}, {
				path: 'YuYanYuAn',
				name: '沙盘预演',
				component: () => import('../views/luzhuangguanqu/GuanQuYuTu/YuYanYuAn')
			}
		],
	},
	// {
	//     path: '/nongCunShuiLi/GuanQuYuTu/GuanQuShuNao',
	//     name: '灌区枢脑',
	//     component: () => import('../views/nongCunShuiLi/GuanQuYuTu/GuanQuShuNao'),
	// },
	{
		path: '/nongCunShuiLi/GuanQuYuTu/ShuJuZhengBian',
		name: '数据整编',
		component: () => import('../views/nongCunShuiLi/GuanQuYuTu/ShuJuZhengBian'),
	}, {
		path: '/nongCunShuiLi/GuanQuYuTu/ShiPinJianShi',
		name: '视频监视',
		component: () => import('../views/nongCunShuiLi/GuanQuYuTu/ShiPinJianShi')
	}, {
		path: '/ShuiXiLianTong',
		name: '水系连通',
		component: () => import('../views/ShuiXiLianTong')
	}, {
		path: '/YongZhenShuiKu',
		name: '永镇水库智水枢脑',
		component: () => import('../views/yongZhenShuiKu_home'),
	}, {
		path: '/YongZhenShuiKu_mianBan',
		name: '永镇水库智水枢脑',
		component: () => import('../views/YongZhenShuiKu/components/x-ShuJuMianBan')
	}, {
		path: '/YongZhenShuiKu_mianBan_liuCun',
		name: '永镇水库智水枢脑',
		component: () => import('../views/YongZhenShuiKu/components/x-ShuJuMianBan_liuCun')
	}, {
		path: '/YongZhenShuiKu2',
		name: '永镇水库智水枢脑',
		component: () => import('../views/YongZhenShuiKu')
	}, {
		path: '/YongZhenShuiKunew',
		name: '永镇水库智水枢脑新',
		component: () => import('../views/ShuiKuZongHe'),
		children: [{
			path: 'zonghetaishi',
			name: '水库综合态势',
			component: () => import('../views/ShuiKuZongHe/zonghetaishi')
		},{
			path: 'zonghetaishi2',
			name: '水库综合态势',
			component: () => import('../views/ShuiKuZongHe/zonghetaishi')
		},{
			path: 'zonghetaishi2',
			name: '水库综合态势',
			component: () => import('../views/ShuiKuZongHe/zonghetaishi')
		},{
			path: 'yunxingguanhu',
			name: '水库运行管护',
			component: () => import('../views/ShuiKuZongHe/yunxingguanhu')
		},{
			path: 'xushuigongshui',
			name: '水库蓄水供水',
			component: () => import('../views/ShuiKuZongHe/xushuigongshui')
		},{
			path: 'peishuidiaodu',
			name: '水库配水调度',
			component: () => import('../views/ShuiKuZongHe/peishuidiaodu')
		},{
			path: 'juzhenguanli',
			name: '水库矩阵管理',
			component: () => import('../views/ShuiKuZongHe/juzhenguanli')
		},{
			path: 'oabangong',
			name: '水库OA办公',
			component: () => import('../views/ShuiKuZongHe/oabangong')
		},]
	},  {
		path: '/luzhuangguanqu_home',
		name: '路庄灌区home',
		component: () => import('../views/luzhuangguanqu_home')
	},  {
		path: '/YongZhenShuiKuYuTunew',
		name: '永镇水库新',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
			path: '',
			name: '永镇水库综合舆图新',
			component: () => import('../views/ZongHeYuTu/main')
		}]
	},{
		path: '/YongZhenShuiKuYuTuOLd',
		name: '永镇水库',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
			path: '',
			name: '永镇水库综合舆图',
			component: () => import('../views/ZongHeYuTu/main')
		}]
	}, {
		path: '/yuhonglanxu',
		name: '雨洪拦蓄',
		component: () => import('../views/yuhonglanxu'),
		children: [{
			path: 'zonghetaishi',
			name: '雨洪拦蓄首页',
			component: () => import('../views/yuhonglanxu/zonghetaishi')
		},]
	},{
		path: '/SiHaoShuiKu',
		name: '四号水库智水枢脑',
		component: () => import('../views/SiHaoShuiKu')
	}, {
		path: '/SiHaoShuiKu2',
		name: '四号水库智水枢脑',
		component: () => import('../views/SiHaoShuiKu')
	}, {
		path: '/SiHaoShuiKuYuTu',
		name: '四号水库',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
			path: '',
			name: '四号水库综合舆图',
			component: () => import('../views/ZongHeYuTu/main')
		}]
	}, {
		path: '/ShengLiShuiKu',
		name: '胜利水库智水枢脑',
		component: () => import('../views/ShengLiShuiKu')
	}, {
		path: '/ShengLiShuiKu2',
		name: '胜利水库智水枢脑',
		component: () => import('../views/ShengLiShuiKu')
	}, {
		path: '/ShengLiShuiKuYuTu',
		name: '胜利水库',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
			path: '',
			name: '胜利水库综合舆图',
			component: () => import('../views/ZongHeYuTu/main')
		}]
	}, {
		path: '/NongCunShuiLiYuTu',
		name: '农村水利',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
				path: '',
				name: '农村水利灌区舆图',
				component: () => import('../views/ZongHeYuTu/main')
			},
			{
				path: 'sluiceMapOnly',
				name: '水闸舆图详情',
				component: () => import('../views/ZongHeYuTu/sluiceMapOnly')
			},
		]
	},{
		path: '/guanquNongCunShuiLiYuTu',
		name: '灌区农村水利',
		component: () => import('../views/ZongHeYuTu'),
		children: [{
				path: '',
				name: '灌区农村水利灌区舆图',
				component: () => import('../views/ZongHeYuTu/main')
			},
			{
				path: 'sluiceMapOnly',
				name: '灌区水闸舆图详情',
				component: () => import('../views/ZongHeYuTu/sluiceMapOnly')
			},
		]
	},{
		path: '/jiaheyutu',
		name: '珈和舆图',
		component: () => import('../views/jiaheyutumain'),
		children: [{
				path: '',
				name: '珈和舆图',
				component: () => import('../views/jiaheyutumain/jiaheindex')
			},
		]
	}, {
		path: '/ZhiNengXiTong',
		name: '智能系统',
		component: () => import('../views/ZhiNengXiTong')
	}, {
		path: '/ShengLiShuiKu',
		name: '智能系统',
		component: () => import('../views/ShengLiShuiKu')
	}, {
		path: '/video',
		name: '智能系统',
		component: () => import('../views/video')
	}, {
		path: '/yingYan',
		name: '鹰眼',
		component: () => import('../views/yingYan')
	}, {
		path: '/threeView',
		name: '鹰眼',
		component: () => import('../views/threeView')
	}, {
		path: '/ShuKuYiMin',
		name: '水库移民',
		component: () => import('../views/ShuKuYiMin')
	}, {
		path: '/ShuWenShuiZiYuan',
		name: '水文水资源',
		component: () => import('../views/ShuWenShuiZiYuan')
	}, {
		path: '/GongChengJianShe',
		name: '工程管理',
		component: () => import('../views/GongChengJianShe')
	},, {
		path: '/siHaoShuiKuBeng',
		name: '工程管理',
		component: () => import('../views/ZhiNengXiTong/components/siHaoShuiKuBeng')
	},
	//改为弹窗
	// {
	//        path: '/ShuiShiGuanLan',
	//        name: '水事观览',
	//        component: () => import( '../views/ShuiShiGuanLan')
	//    }
]

const router = new VueRouter({
	routes
})

export default router
